import { config } from '@/config';
import { StudentEntity } from '@/domains/student/entities';
import i18n from '@/i18n';
import { StudentProfile } from '@/modules/auth/types';
import { useStudentViewModel } from '@/modules/home/viewmodels/student';
import { PUBLIC_PATHS } from '@/routes';
import { get } from '@/utils/mobile-app-storage';
import { Capacitor } from '@capacitor/core';
import { TFunction } from 'i18next';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAccessToken, setAccessToken, setCurrentStudentId } from '../storages';
import { pathToRegexp } from 'path-to-regexp';

export interface AppContextProps {
  t: TFunction;
  changeLanguage(lng: string): Promise<TFunction>;
  currentLanguage: string;
  currentStudent?: StudentEntity;
  authenticated: boolean;
  platform?: string; //  Current platform is running: web, ios, or android
  isNativePlatform: boolean; // True if platform is ios or android
  selectedProfile: StudentProfile | undefined;
  setSelectedProfile(value: StudentProfile | undefined): void;
  setAuthenticated(value: boolean): void;
  profiles: { [key: string]: StudentProfile };
  setProfiles(value: { [key: string]: StudentProfile }): void;
  isReady: boolean;
}

export const App = React.createContext<AppContextProps | undefined>(undefined);

export interface AppProviderProps {
  children: React.ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [authenticated, setAuthenticated] = useState<boolean>(Boolean(getAccessToken()));
  const [profileChanged, setProfileChanged] = useState<boolean>(false);
  const { student, actionGetCurrentStudent } = useStudentViewModel();
  const { t } = useTranslation();
  const { language, changeLanguage } = i18n;
  const [platform, setPlatform] = useState<string | undefined>(undefined);
  const isNativePlatform = Capacitor.isNativePlatform();
  const [profiles, setProfiles] = useState<{ [key: string]: StudentProfile }>({});
  const [isReady, setIsReady] = useState<boolean>(false);
  const [selectedProfile, setSelectedProfile] = useState<StudentProfile | undefined>(undefined);
  const navigate = useNavigate();
  useEffect(() => {
    if (authenticated) {
      actionGetCurrentStudent();
      setProfileChanged(false);
    }
    /**
     * Get platform
     */
    setPlatform(Capacitor.getPlatform());
  }, [authenticated]);

  const handleMobileAppReady = async () => {
    const cachedProfiles = await get<{ [key: string]: StudentProfile }>('studentProfiles');
    const cachedProfileIdSelected = await get<string>('profileSelected');
    // console.log('cachedProfiles', cachedProfiles);
    if (cachedProfiles) {
      setProfiles(cachedProfiles);
    }
    if (cachedProfileIdSelected && cachedProfiles) {
      const profile = cachedProfiles[Number(cachedProfileIdSelected)];
      // console.log('cachedProfileIdSelected', cachedProfileIdSelected, profile);
      if (profile) {
        setSelectedProfile(profile);
        const accessToken = profile?.accessToken;
        const id = profile?.id;
        if (accessToken && id) {
          setAccessToken(accessToken);
          setCurrentStudentId(id);
        }
      }
    }
  };

  // const isPublicPath = (path: string, publicPaths: Record<string, string>) => {
  //   return Object.values(publicPaths).some((publicPath) => {
  //     const regexp = pathToRegexp(publicPath);
  //     return regexp.regexp.test(path);
  //   });
  // };

  // const currentPath = useLocation().pathname;

  useEffect(() => {
    if (isNativePlatform && isEmpty(profiles) && isReady) {
      navigate(PUBLIC_PATHS.login);
    }
  }, [profiles, isReady]);

  useEffect(() => {
    if (isNativePlatform) {
      handleMobileAppReady().then(() => {
        setIsReady(true);
      });
    } else {
      setIsReady(true);
    }
  }, []);

  const contextValue = useMemo(
    () => ({
      t,
      changeLanguage,
      currentLanguage: language || config.i18n.defaultLang,
      authenticated,
      setAuthenticated,
      currentStudent: student,
      platform,
      isNativePlatform,
      profiles,
      isReady,
      setProfiles,
      selectedProfile,
      setSelectedProfile,
    }),
    [
      t,
      changeLanguage,
      language,
      authenticated,
      student,
      isReady,
      profiles,
      isNativePlatform,
      selectedProfile,
      profileChanged,
    ],
  );

  return <App.Provider value={contextValue}>{children}</App.Provider>;
};
