import { LoginDto, MobileAppLoginDto } from '../dtos';
import { AUTH_LOGIN } from '../../graphql';
import { HttpClientAdapter } from '@/graphql/http-client-adapter';
import { AuthUserEntity } from '@/domains/auth/entities';
import { AuthUserRepository } from '@/domains/auth/ports/repositories';

export class AuthUserRepoImpl implements AuthUserRepository {
  async login(payload: LoginDto | MobileAppLoginDto): Promise<AuthUserEntity> {
    return HttpClientAdapter.query<AuthUserEntity>({
      query: AUTH_LOGIN,
      dataKey: 'Student_login',
      variables: { payload },
    });
  }
}
