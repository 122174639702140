import { config } from '@/config';

export const getAccessToken = (): string => {
  return localStorage.getItem(config.jwtAuth.storageAccessTokenName) || '';
};

export const setAccessToken = (token: string): void => {
  localStorage.setItem(config.jwtAuth.storageAccessTokenName, token);
};

export const removeAuthenticatedToken = (): void => {
  localStorage.removeItem(config.jwtAuth.storageAccessTokenName);
  localStorage.removeItem(config.jwtAuth.storageStudentIDName);
};

export const removeAccessToken = (): void => {
  localStorage.removeItem(config.jwtAuth.storageAccessTokenName);
  removeAuthenticatedToken();
};

export const getCurrentStudentId = (): string => {
  return localStorage.getItem(config.jwtAuth.storageStudentIDName) || '';
};

export const setCurrentStudentId = (id: string): void => {
  localStorage.setItem(config.jwtAuth.storageStudentIDName, id);
};
