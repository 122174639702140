import { useAppContext, useBrowserDevice } from '@/common';
import { Loading } from '@/components/Loading';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { NavBar } from '../Navbar';

export const BlankLayout: React.FC<unknown> = (): JSX.Element => {
  const { isMobile } = useBrowserDevice();
  const { isReady } = useAppContext();
  const loading = () => {
    return (
      <div className="text-center pt-5">
        <div role="status">
          <Loading />
        </div>
      </div>
    );
  };

  return (
    <div className="w-full flex flex-col bg-white sm:bg-black/[.02]">
      {!isMobile && <NavBar toggleSideBar={() => undefined} />}
      <div className="container m-auto">{!isReady ? loading() : <Outlet />}</div>
    </div>
  );
};
