/* eslint-disable new-cap */
/* eslint-disable radix */
/* eslint-disable react/button-has-type */
import React, { useEffect, useRef } from 'react';
import { FaDownload } from 'react-icons/fa';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useNavigate, useParams } from 'react-router-dom';
import { PUBLIC_PATHS } from '@/routes';

const PaymentRequestScreen: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    console.log('id', id === '67c188557a9d2d163ade4718', id);

    if (id !== '67c188557a9d2d163ade4718') {
      navigate(PUBLIC_PATHS.notFound);
    }
  }, [id]);
  const invoiceRef = useRef<HTMLDivElement>(null);

  const mockData = {
    id: '67c188557a9d2d163ade4718',
    title: 'hoc phi KID 1 thang 1',
    paymentDueDate: '1743379200000',
    amount: '2000',
    note: 'Hoc phi: 4.250.000 | Ban tru: 21 buoi (nghi 1, hoan 1) - tong thu 20 buoi: 1.200.000 | Da ngoai thang 1: 250.000 | Steam: 300.000 | Toan tu duy: 300.000 | Aerobic: 100.000 | Phi trong muon: 690.000 | Hoc thu 7: 250.000 | An chieu: 400.000 | Dong phuc: 250.000 | Phi Tomia: thang truoc: 35.000, thang nay: 35.000 - tong thu: 70.000.',
    status: 'SENDING_FAILED',
    customerPaymentRequestBatchId: '67c1884e7a9d2d163ade46fe',
    sentBy: '',
    sentAt: 'Fri Feb 28 2025 10:46:23 GMT+0000 (Coordinated Universal Time)',
    centre: {
      id: null,
      name: '71 Nguyễn Chí Thanh',
      shortName: '71 Nguyễn Chí Thanh',
      isActive: null,
      address: null,
    },
    productUser: { name: 'Võ Văn Trí', phoneNumber: '0972540825', referenceCode: 'STD20240012' },
    bankAccount: {
      bankCode: 'MB',
      bankName: 'Ngân Hàng Quân Đội',
      id: '67c193ffc13e678d3b11f256',
      bankAccountNumber: 'VQRQABMYF9328',
      bankAccountName: 'CONG TY CP TRUONG HOC CONG NGHE MINDX',
      createAt: null,
      expireDate: null,
      referenceId: 'single-cpr-67c188557a9d2d163ade4718',
    },
    isPaymentAcknowledged: null,
  };

  const studentId = mockData.productUser.referenceCode;
  const bankId = mockData.bankAccount.bankCode;
  const accountNo = mockData.bankAccount.bankAccountNumber;
  const accountName = mockData.bankAccount.bankAccountName;
  const amount = parseInt(mockData.amount);
  const description = mockData.bankAccount.referenceId;

  const encodedAccountName = encodeURIComponent(accountName);
  const encodedDescription = encodeURIComponent(description);

  const noteItems = [
    { description: 'Học phí', amount: '4.250.000' },
    { description: 'Bận trừ: 21 buổi (nghỉ 1, hoàn 1) - tổng thu 20 buổi', amount: '1.200.000' },
    { description: 'Đã ngoại tháng 1', amount: '250.000' },
    { description: 'Steam', amount: '300.000' },
    { description: 'Toán tư duy', amount: '300.000' },
    { description: 'Aerobic', amount: '100.000' },
    { description: 'Phí trông muộn', amount: '690.000' },
    { description: 'Học thứ 7', amount: '250.000' },
    { description: 'Ăn chiều', amount: '400.000' },
    { description: 'Đồng phục', amount: '250.000' },
    { description: 'Phí Tomia tháng trước', amount: '35.000' },
    { description: 'Phí Tomia tháng này', amount: '35.000' },
    { description: 'Sách giáo khoa', amount: '450.000' },
    { description: 'Dụng cụ học tập', amount: '150.000' },
    { description: 'Phí xe đưa đón', amount: '800.000' },
    { description: 'Bảo hiểm học sinh', amount: '120.000' },
    { description: 'Phí dã ngoại', amount: '500.000' },
    { description: 'Đồ chơi giáo dục', amount: '280.000' },
    { description: 'Phí vệ sinh', amount: '50.000' },
    { description: 'Phí phát triển trường', amount: '200.000' },
    { description: 'Học liệu tháng 3', amount: '180.000' },
    { description: 'Phí tổ chức sự kiện', amount: '150.000' },
    { description: 'Đồng phục thể dục', amount: '180.000' },
    { description: 'Phí học thêm buổi', amount: '300.000' },
    { description: 'Phí bán trú', amount: '600.000' },
  ];

  const vietQrUrl = `https://img.vietqr.io/image/${bankId}-${accountNo}-compact.png?amount=${amount}&addInfo=${encodedDescription}&accountName=${encodedAccountName}`;

  const totalFromNote = noteItems.reduce(
    (sum, item) => sum + parseInt(item.amount.replace(/\./g, '')),
    0,
  );

  const dueDate = new Date(parseInt(mockData.paymentDueDate)).toLocaleDateString('vi-VN', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  const sentAtDate = new Date(mockData.sentAt).toLocaleDateString('vi-VN', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  const preloadImage = (url: string) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous'; // Đảm bảo tải hình ảnh từ nguồn ngoài
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = url;
    });
  };

  const handleDownloadPDF = async () => {
    if (!invoiceRef.current) return;
    const downloadButton = document.querySelector('.download-pdf-button');
    try {
      if (downloadButton) downloadButton.classList.add('hidden');

      // Preload cả logo và QR code
      await Promise.all([
        preloadImage('/logo.png'), // Tải trước logo
        preloadImage(vietQrUrl), // Tải trước QR code
      ]);

      const mainContainer = invoiceRef.current.parentElement;
      if (!mainContainer) return;

      // Temporarily adjust the container to capture full height
      const originalHeight = mainContainer.style.height;
      const originalOverflow = mainContainer.style.overflow;
      mainContainer.style.height = 'auto';
      mainContainer.style.overflow = 'visible';

      // Calculate the full scroll height
      const fullHeight = mainContainer.scrollHeight;
      const fullWidth = mainContainer.offsetWidth;

      // Capture the full content with html2canvas
      const canvas = await html2canvas(mainContainer, {
        scale: 2,
        useCORS: true, // Bật hỗ trợ cross-origin
        logging: false,
        backgroundColor: '#f3f4f6',
        width: fullWidth,
        height: fullHeight,
        windowWidth: fullWidth,
        windowHeight: fullHeight,
      });

      // Restore original styles
      mainContainer.style.height = originalHeight;
      mainContainer.style.overflow = originalOverflow;

      if (downloadButton) downloadButton.classList.remove('hidden');

      const imgData = canvas.toDataURL('image/png', 1.0);
      const pdf = new jsPDF('portrait', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;

      // Calculate the scaling ratio to fit width to A4
      const ratio = pdfWidth / imgWidth;
      const scaledWidth = imgWidth * ratio;
      const scaledHeight = imgHeight * ratio;

      // Handle multi-page PDF
      let heightLeft = scaledHeight;
      let position = 0;

      pdf.addImage(imgData, 'PNG', 0, position, scaledWidth, scaledHeight);
      heightLeft -= pdfHeight;

      while (heightLeft > 0) {
        position -= pdfHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, scaledWidth, scaledHeight);
        heightLeft -= pdfHeight;
      }

      pdf.save(`Hoa-don-${studentId}-${new Date().toISOString().split('T')[0]}.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
      if (downloadButton) downloadButton.classList.remove('hidden');
    }
  };

  return (
    <div
      className="min-h-screen bg-gray-100 flex items-center justify-center p-2 sm:p-4 font-sans"
      ref={invoiceRef}
    >
      <div className="w-full max-w-5xl mx-auto bg-white rounded-lg shadow-md p-4 sm:p-6">
        {/* Logo and Download Button */}
        <div className="flex justify-between items-center mb-2">
          <div>
            <button
              onClick={handleDownloadPDF}
              className="download-pdf-button flex items-center gap-1.5 px-3 py-1.5 text-blue-600 border border-blue-200 rounded-lg hover:bg-blue-50 transition-colors duration-200 cursor-pointer text-xs sm:text-sm"
            >
              <FaDownload className="w-3 h-3 sm:w-3.5 sm:h-3.5" />
              <span className="font-medium">Tải PDF</span>
            </button>
          </div>

          <img
            src="/logo.svg"
            alt="MindX"
            className="w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24 object-contain"
            crossOrigin="anonymous"
          />
        </div>
        {/* Main Content */}
        <div className="flex flex-col lg:flex-row gap-6">
          {/* Left Section */}
          <div className="flex-1">
            <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start gap-2 sm:gap-4">
              <div className="order-2 sm:order-1">
                <div className="text-xl sm:text-2xl font-bold text-gray-900">Hóa đơn</div>
                <p className="text-xs sm:text-sm text-gray-600">MindX Technology School</p>
                <p className="text-xs sm:text-sm text-gray-600">{mockData.centre.name}</p>
                <p className="text-xs sm:text-sm text-gray-600">Hà Nội, Việt Nam</p>
                <p className="text-xs sm:text-sm text-gray-600">info@mindx.com.vn</p>
              </div>
              <div className="order-1 sm:order-2 text-left sm:text-right">
                <p className="text-xs sm:text-sm font-semibold text-gray-900">
                  Số hóa đơn: {studentId}
                </p>
                <p className="text-xs sm:text-sm text-gray-600">Ngày phát hành: {sentAtDate}</p>
                <p className="text-xs sm:text-sm text-gray-600">Ngày đến hạn: {dueDate}</p>
              </div>
            </div>
            <div className="mt-4">
              <p className="text-xs sm:text-sm font-semibold text-gray-900">Gửi đến:</p>
              <p className="text-xs sm:text-sm text-gray-600">{mockData.productUser.name}</p>
              <p className="text-xs sm:text-sm text-gray-600">
                Số điện thoại: {mockData.productUser.phoneNumber}
              </p>
            </div>
            <div className="w-full text-xs sm:text-sm text-gray-700 mt-4">
              {/* Header */}
              <div className="border-t-2 border-b border-gray-300 bg-gray-100 flex">
                <div className="flex-1 text-left p-2">Mô tả</div>
                <div className="flex-1 text-right p-2">Số tiền</div>
              </div>
              {/* Body */}
              <div>
                {noteItems.map((item, index) => (
                  <div
                    key={index}
                    className="border-dashed border-gray-300 flex border-[0px] border-b"
                  >
                    <div className="flex-1 p-2">{item.description}</div>
                    <div className="flex-1 text-right p-2">{item.amount} VND</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* Right Section */}
          <div className="w-full lg:w-80 mt-0 lg:mt-0">
            <div className="text-right">
              <div className="text-lg sm:text-xl font-bold text-gray-900 mb-2">
                Số tiền cần thanh toán: {totalFromNote.toLocaleString()} VND
              </div>
              <span className="text-xs sm:text-sm text-gray-600 font-semibold">Đến hạn: </span>
              <span className="text-xs sm:text-sm text-gray-600">{dueDate}</span>
            </div>
            <div className="text-right mt-2">
              <p className="text-xs sm:text-sm text-gray-700">
                <span className="font-semibold">Tạm tính:</span> {totalFromNote.toLocaleString()}{' '}
                VND
              </p>
              <p className="text-xs sm:text-sm text-gray-700">
                <span className="font-semibold">Tổng cộng:</span> {totalFromNote.toLocaleString()}{' '}
                VND
              </p>
            </div>
            <p className="text-xs sm:text-sm text-gray-600 italic mt-4 md:mt-2">
              Cảm ơn Quý Phụ Huynh đã tin tưởng và đồng hành cùng MindX.
              <br />
              <span className="text-red-600 font-semibold">Lưu ý:</span> Quý Phụ Huynh vui lòng
              không thay đổi số tiền chuyển khoản. Trường hợp chuyển không đúng số tiền, Ngân hàng
              sẽ tự động trả lại. Mọi thắc mắc, xin vui lòng liên hệ MindX để được hỗ trợ.
            </p>

            <div className="bg-blue-50 px-3 py-4 rounded-lg mt-4">
              <p className="text-sm sm:text-sm font-semibold text-gray-800 mb-3">
                Thông tin thanh toán qua chuyển khoản:
              </p>
              <div className="flex flex-col gap-2">
                <div className="text-sm sm:text-sm text-gray-700">
                  <span className="font-semibold">Ngân hàng:</span> {mockData.bankAccount.bankName}
                </div>
                <div className="text-sm sm:text-sm text-gray-700">
                  <span className="font-semibold">Tên tài khoản:</span> {accountName}
                </div>
                <div className="text-sm sm:text-sm text-gray-700">
                  <span className="font-semibold">Số tài khoản:</span> {accountNo}
                </div>
                <div className="text-sm sm:text-sm text-gray-700">
                  <span className="font-semibold">Số tiền:</span> {totalFromNote.toLocaleString()}{' '}
                  VND
                </div>
                <div className="text-sm sm:text-sm text-gray-700">
                  <span className="font-semibold">Nội dung:</span> {description}
                </div>
              </div>
              <div className="mt-4 pt-2 border-t border-blue-100">
                <p className="text-sm sm:text-sm font-semibold text-gray-800 mb-2 text-center">
                  Hoặc quét mã QR để thanh toán:
                </p>
                <div className="flex justify-center">
                  <div className="bg-white p-2 rounded-lg shadow-md">
                    <img src={vietQrUrl} alt="Mã QR thanh toán VietQR" className="w-full h-full" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentRequestScreen;
