export const PUBLIC_PATHS = {
  login: '/login',
  notFound: '/404',
  profileSelection: '/profile-selection',
  customerPaymentRequest: '/customer-payment-request/:id',
};

export const PROTECTED_PATHS = {
  home: '/',
  profile: '/profile',
  learningProgress: '/learning-progress',
  learningProgressDetail: '/learning-progress/:id',
  studyProduct: '/study-products',
  studentCourses: '/student-courses',
};

export const getRoutePathWithParameters = (
  pathname: string,
  params?: { [key: string]: string },
): string => {
  if (!pathname || !params) return pathname;

  let newPath = pathname;
  Object.entries(params).forEach(([k, v]: [string, string]) => {
    newPath = newPath.replace(`:${k}`, v);
  });
  return newPath;
};
