import { AuthUserEntity } from '../entities';
import { LoginPayload } from '../ports/payloads';
import { AuthUserRepository } from '../ports/repositories';

export class LoginUseCase {
  constructor(private readonly repo: AuthUserRepository) {}

  async run(payload: LoginPayload): Promise<AuthUserEntity> {
    return this.repo.login(payload);
  }
}
