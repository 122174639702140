/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useAppContext } from '@/common/hooks';
import logo from '@/static/image/logo.svg';
import React from 'react';
import { HiMenu } from 'react-icons/hi';
import { Link } from 'react-router-dom';

type Props = {
  isMobile?: boolean;
  toggleSideBar(): void;
};

export const NavBar: React.FC<Props> = (props: Props): JSX.Element => {
  const { isMobile = false, toggleSideBar } = props;
  const { t, currentLanguage, changeLanguage } = useAppContext();

  const handleChangeLanguage = async (lng: string) => {
    await changeLanguage(lng);
    localStorage.setItem('lang', lng);
  };

  return (
    <header
      className="
        fixed
        flex
        justify-center
        w-full
        bg-white
        lg:px-4
        z-[9999]
        safe-area-top
        shadow-[0px_4px_8px_0px_rgba(0,0,0,0.15)]
      "
    >
      <div className="container flex items-center box-border overflow-hidden">
        {isMobile ? (
          <div className="flex items-center justify-between w-full px-4 h-16">
            <div className="flex items-center">
              <button
                className="p-3 cursor-pointer bg-white border-0 text-red-600"
                type="button"
                onClick={toggleSideBar}
              >
                <HiMenu className="w-5 h-5" />
              </button>
              <div className="uppercase font-bold text-base text-red-600">
                {t('common:mobileHeaderTitle')}
              </div>
            </div>
            <Link to="/">
              <img src={logo} alt="logo" className="w-[68px] h-[32px]" />
            </Link>
          </div>
        ) : (
          <div className="flex items-center h-20">
            <div className="border-r-[1px] border-l-0 border-y-0 border-solid border-red-600 pr-4">
              <Link to="/">
                <img src={logo} alt="logo" className="w-[108px] h-14" />
              </Link>
            </div>
            <div className="text-red-700 text-xl pl-4 font-semibold">{t('common:headerTitle')}</div>
          </div>
        )}
      </div>
      {/* <div className="cursor-pointer flex items-center gap-1 pr-8">
        <img
          src={viLang}
          alt=""
          width={30}
          className={currentLanguage === 'en' ? 'opacity-25' : ''}
          onClick={() => handleChangeLanguage('vi')}
          onKeyDown={() => handleChangeLanguage('vi')}
          title={t('common:vi')}
        />
        <img
          src={enLang}
          alt=""
          width={30}
          className={currentLanguage === 'vi' ? 'opacity-25' : ''}
          onClick={() => handleChangeLanguage('en')}
          onKeyDown={() => handleChangeLanguage('vi')}
          title={t('common:en')}
        />
      </div> */}
    </header>
  );
};
