import { useAppContext } from '@/common/hooks';
import { useWindowSize } from '@/common/hooks/useWindowSize';
import { MobileAppLoginForm } from '@/modules/auth/components/login-form/MobileAppLoginForm';
import { CopyRight, LoginTitle } from '../../components';
import { useEffect } from 'react';
import { StatusBar } from '@capacitor/status-bar';

export const MobileApp = () => {
  const { t } = useAppContext();
  const { height } = useWindowSize();
  // const paddingTopClass = height < 700 ? 'pt-16' : 'pt-[134px]';

  useEffect(() => {
    StatusBar.setOverlaysWebView({ overlay: true });
  }, []);

  return (
    <div
      className={`
          flex
          flex-col
          items-center
          justify-between
          box-border
          w-full
          px-4
          pb-10
          gap-8
          h-fit
          min-h-[100vh]
          bg-cover
          bg-no-repeat
          bg-[url('/src/static/image/auth/mobile-bg-login-cover.png')]
          pt-[96px]
        `}
    >
      <div
        className="
          flex
          flex-col
          items-center
          gap-8
          w-full
        "
      >
        <LoginTitle t={t} />
        <MobileAppLoginForm />
      </div>
      <CopyRight t={t} />
    </div>
  );
};
