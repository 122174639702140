import { useAppContext, useSearchQueryParams } from '@/common';
import {
  getAccessToken,
  getCurrentStudentId,
  removeAccessToken,
  setCurrentStudentId,
} from '@/common/storages';
import { PROTECTED_PATHS, PUBLIC_PATHS } from '@/routes';
import { useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

export const ProtectedRoutes = () => {
  const token = getAccessToken();
  const navigate = useNavigate();
  const previousStudentId = getCurrentStudentId();
  const { studentId } = useSearchQueryParams();
  const { isReady, profiles, isNativePlatform, selectedProfile, authenticated } = useAppContext();

  useEffect(() => {
    if (!isNativePlatform) return; // web version do not have Profile selection screen;

    // handle redirect of mobile app
    if (isReady) {
      if (isNativePlatform && selectedProfile) {
        if (authenticated) {
          navigate(PROTECTED_PATHS.home);
        }
      } else if (!isEmpty(profiles)) {
        navigate(PUBLIC_PATHS.profileSelection);
      } else {
        navigate(PUBLIC_PATHS.login);
      }
    }
  }, [isReady, isNativePlatform]);

  useEffect(() => {
    const redirectPath = PUBLIC_PATHS.notFound; // Uncomment this line when you develop the mobile app
    if (!isNativePlatform && !studentId && !previousStudentId) {
      navigate(redirectPath);
    }
  }, [studentId, previousStudentId]);

  const isDifferentStudent = studentId && studentId !== previousStudentId;
  if ((isDifferentStudent || !token) && !isNativePlatform) {
    removeAccessToken();
    if (studentId) {
      setCurrentStudentId(studentId);
    }
    console.log('redirect to login');
    return <Navigate to={`${PUBLIC_PATHS.login}?studentId=${studentId || previousStudentId}`} />;
  }
  return <Outlet />;
};
