/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { Link } from 'react-router-dom';
import { ScopeLoading } from '@/components';
import { Button, Typography } from '@mx/ui';
import { useTranslation } from 'react-i18next';
import { LoginFormHeader } from './FormHeader';
import { useMobileAppLoginForm } from '../../viewmodels/mobile-app-login-fom.view-model';
import { PUBLIC_PATHS } from '@/routes';
import { useAppContext } from '@/common';

interface MobileAppLoginFormProps {
  studentId?: string;
}
export const MobileAppLoginForm = ({ studentId }: MobileAppLoginFormProps) => {
  const { t } = useTranslation();
  const timeRef = useRef<any>();
  const { login, form, isLoading, error, isInvalidAccessTokenState } = useMobileAppLoginForm();
  const { profiles } = useAppContext();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = form;

  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);

  const handleDebounceFn = (value: boolean) => {
    setIsInputFocused(value);
  };

  const debounceFn = useMemo(() => debounce(handleDebounceFn, 100), []);

  const onFocusInput = () => debounceFn(true);
  const onBlurInput = () => debounceFn(false);

  useEffect(() => {
    if (isInputFocused) {
      timeRef.current = setTimeout(() => {
        window.scrollTo({
          top: 200,
          behavior: 'smooth',
        });
      }, 120);
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    return () => {
      if (timeRef.current) {
        clearTimeout(timeRef.current);
      }
    };
  }, [isInputFocused]);

  return (
    <>
      <div
        className="
        flex
        flex-col
        w-full
        bg-cover
        bg-no-repeat
        p-8
        rounded-2xl
        md:rounded-none
        min-w-[100%]
        md:min-w-[450px]
        md:w-[37%]
        items-center
        justify-center
        box-border
        relative
        bg-[url('/src/static/image/auth/bg-login-form.png')]
      "
      >
        <form onSubmit={handleSubmit((values) => login(values))} className="w-full">
          <LoginFormHeader t={t} />
          <label htmlFor="studentId" className="block text-sm font-medium text-gray-700 mb-2">
            {t('common:studentIdentify')}
          </label>
          <div className="flex flex-col mt-2">
            <div className="relative mb-3 w-full">
              <input
                {...register('studentId', { required: true, maxLength: 20 })}
                placeholder={t('common:identifyPlaceholder') || ''}
                type="text"
                className={`
                py-1.5
                md:py-3
                px-2
                w-full
                text-base
                border
                box-border
                rounded-lg
                border-solid
                ${errors.studentId ? 'border-red-600' : 'border-gray-600'}
              `}
                onFocus={onFocusInput}
                onBlur={onBlurInput}
              />
              {Boolean(errors.studentId) && (
                <Typography
                  fontTypo="body-xs-desktop"
                  weight="regular"
                  content={errors.studentId?.message}
                  className="text-mx-red-600 mt-2 text-right"
                />
              )}
            </div>

            <div className="mb-0">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-2">
                {t('common:password')}
              </label>
              <div className="flex gap-2 justify-between">
                <input
                  {...register('password', { required: true, maxLength: 10 })}
                  placeholder={t('common:passwordPlaceholder') || ''}
                  type="password"
                  onFocus={onFocusInput}
                  onBlur={onBlurInput}
                  className={`
                    py-1.5
                    md:py-3
                    px-2
                    w-full
                    text-base
                    border
                    box-border
                    rounded-lg
                    border-solid
                    ${errors.password ? 'border-red-600' : 'border-gray-600'}
                  `}
                />
              </div>
            </div>

            {Boolean(error) && (
              <Typography
                fontTypo="body-xs-desktop"
                weight="regular"
                content={t('common:invalidLoginMsg')}
                className="text-mx-red-600 mt-2 text-right"
              />
            )}
          </div>
          <div className="mt-5 flex justify-center">
            <Button
              htmlType="submit"
              type="filled-danger"
              size="large"
              content={t('common:loginBtn')}
              className="
              font-semibold
              text-xl
              border-0
              min-w-[150px]
              cursor-pointer
              shadow-[0_4px_4px_0_rgba(0,0,0,0.25)]
            "
            />
          </div>
          {isInvalidAccessTokenState && (
            <>
              <div className="text-red-600 mb-3 text-center text-sm mt-3">
                {t('common:tokenExpiredMsg')}
              </div>
            </>
          )}
          {profiles && Object.keys(profiles).length > 0 && (
            <>
              <div className="text-center mt-3">{t('common:or')}</div>
              <div className="text-center">
                <Link
                  to={PUBLIC_PATHS.profileSelection}
                  className="w-full no-underline text-rose-600"
                >
                  <Typography
                    fontTypo="body-xs-desktop"
                    weight="regular"
                    content={t('common:accountSelection')}
                    className="text-mx-blue-600 mt-2 text-center text-xl"
                  />
                </Link>
              </div>
            </>
          )}
        </form>
        {isLoading && <ScopeLoading />}
      </div>
      <div className={`w-full ${isInputFocused ? 'h-[200px]' : 'h-0'}`} />
    </>
  );
};
