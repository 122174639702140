import React from 'react';
import _ from 'lodash';
import { useAppContext } from '@/common';
import LogoIcon from '@/static/image/logo.svg';
import YoutubeIcon from '@/static/icons/Youtube.svg';
import LinkedInIcon from '@/static/icons/LinkedIn.svg';
import InstagramIcon from '@/static/icons/Instagram.svg';
import FaceBookIcon from '@/static/icons/FaceBook.svg';
import TikTokIcon from '@/static/icons/TikTok.svg';
import LegalImage from '@/static/image/legal.png';
import { config } from '@/config';

type SocialMediaItemType = {
  link: string;
  icon: React.ReactNode;
};

type ContactType = {
  label: string;
  contact: string;
  order: number;
};

export const FooterContact = (): JSX.Element => {
  const { t } = useAppContext();

  const { contacts, socialMediaChannels } = config;

  const socialMediaItems: SocialMediaItemType[] = [
    {
      link: socialMediaChannels.faceBook || '',
      icon: <img src={FaceBookIcon} alt="youtube" />,
    },
    {
      link: socialMediaChannels.youtube || '',
      icon: <img src={YoutubeIcon} alt="youtube" />,
    },
    {
      link: socialMediaChannels.linkedIn || '',
      icon: <img src={LinkedInIcon} alt="linked in" />,
    },
    {
      link: socialMediaChannels.instagram || '',
      icon: <img src={InstagramIcon} alt="instagram" />,
    },
    {
      link: socialMediaChannels.tiktok || '',
      icon: <img src={TikTokIcon} alt="tiktok" />,
    },
  ].filter((item: SocialMediaItemType) => !!item.link);

  const kidCourseContacts: ContactType[] = [
    {
      label: t('common:kidCourseContactHN'),
      contact: contacts.kidCourseHN,
      order: 3,
    },
    {
      label: t('common:kidCourseContactHCM'),
      contact: contacts.kidCourseHCM,
      order: 4,
    },
    {
      label: t('common:kidCourseContactOther'),
      contact: contacts.kidCourseOther,
      order: 5,
    },
  ];

  const mixedContacts: ContactType[] = [
    {
      label: t('common:complainContact'),
      contact: contacts.complainGeneral,
      order: 1,
    },
    {
      label: t('common:adultCourseContact'),
      contact: contacts.adultCourse,
      order: 2,
    },
    {
      label: t('common:emailContact'),
      contact: contacts.email,
      order: 6,
    },
  ];

  const allContacts = _.orderBy([...kidCourseContacts, ...mixedContacts], 'order', 'asc');

  return (
    <div
      className="
        w-full
        bg-inherit
        bg-white
        pb-0
        pt-4
        sm:py-20
      "
    >
      <div
        className="
          container
          flex
          gap-x-6
          m-auto
          flex-col
          sm:flex-row
          px-4
          py-6
          sm:py-0
          sm:px-0
          box-border
          gap-10
        "
      >
        <div
          className="
            flex
            flex-row
            sm:flex-col
            flex-wrap
            gap-3
            sm:gap-6
            min-w-[300px]
            justify-between
            sm:justify-start
            order-2
            sm:order-1
          "
        >
          <div className="order-1">
            <img
              src={LogoIcon}
              alt="logo"
              className="object-cover ml-[-8px] w-[109px] sm:w-[147px] h-[48px] sm:h-[64px]"
            />
          </div>
          <div className="flex w-full gap-2 order-3 sm:order-2">
            {socialMediaItems.map((item: SocialMediaItemType, index: number) => (
              <a key={index} href={item.link} target="_blank" rel="noreferrer">
                {item.icon}
              </a>
            ))}
          </div>
          <div className="order-1 sm:order-3 flex items-center">
            <img
              src={LegalImage}
              alt="legal"
              className="object-cover w-[109px] sm:w-[82px] h-[42px] sm:h-[32px]"
            />
          </div>
        </div>
        <div className="grow text-sm sm:text-xl order-1 sm:order-2 text-gray-900">
          <div
            className="
              text-base
              sm:text-[32px]
              sm:leading-10
              font-bold
              pb-2
              pt-0
              sm:pt-1
              border-0
              border-b
              border-solid
              border-red-600
            "
          >
            {t('common:footerContactTitle')}
          </div>
          <div className="mt-6">
            <div className="font-semibold sm:font-normal">{t('common:footerContactDes')}</div>
            <div className="hidden sm:flex gap-x-4 flex-wrap">
              <ul className="list-disc pl-6 flex flex-col gap-6 m-0 mt-6">
                {kidCourseContacts.map((item: ContactType, index: number) => (
                  <li key={index}>
                    {item.label}: {item.contact}
                  </li>
                ))}
              </ul>
              <ul className="list-disc pl-6 flex flex-col gap-6 m-0 mt-6">
                {mixedContacts.map((item: ContactType, index: number) => (
                  <li key={index}>
                    {item.label}: {item.contact}
                  </li>
                ))}
              </ul>
            </div>
            <div className="flex sm:hidden">
              <ul className="list-disc pl-6 flex flex-col gap-2 m-0 mt-6">
                {allContacts.map((item: ContactType, index: number) => (
                  <li key={index}>
                    {item.label}: {item.contact}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FooterCopyright = (): JSX.Element => {
  const { t } = useAppContext();
  const { taxCode } = config;
  return (
    <div
      className="
        w-full
        bg-gray-900
        text-white
        px-4
        py-4
        sm:py-10
        box-border
        overflow-hidden
        safe-area-bottom
      "
    >
      <div
        className="
          container
          flex
          flex-col
          sm:flex-row
          gap-x-6
          m-auto
          gap-4
          sm:gap-2
          text-xs
          sm:text-sm
        "
      >
        <div className="w-full sm:w-2/3 flex flex-wrap flex gap-2 order-2 sm:order-1">
          <span>{t('common:footerCompanyName')}</span>
          <span>
            {t('common:footerTax')}: {taxCode}
          </span>
          <span>{t('common:footerAddress')}</span>
        </div>
        <div className="w-full sm:w-1/3 order-1 sm:order-2">{t('common:footerCopyright')}</div>
      </div>
    </div>
  );
};

export const Footer = (): JSX.Element => {
  const { isNativePlatform } = useAppContext();
  return (
    <div className="w-full">
      {!isNativePlatform && <FooterContact />}
      <FooterCopyright />
    </div>
  );
};
