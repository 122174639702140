import { useBrowserDevice, useSearchQueryParams } from '@/common';
import {
  getAccessToken,
  getCurrentStudentId,
  removeAccessToken,
  setCurrentStudentId,
} from '@/common/storages';
import { PROTECTED_PATHS, PUBLIC_PATHS } from '@/routes';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export interface LoginProps {
  currentStudentId: string | null;
  studentId: string | null;
  token: string | null;
  isDifferentStudent: boolean;
}

export const useLogin = (): LoginProps => {
  const location = useLocation();
  const currentStudentId = getCurrentStudentId();
  const { studentId } = useSearchQueryParams();
  const navigate = useNavigate();
  const localToken = getAccessToken();
  const [token] = useState<string | null>(localToken);
  const { isNativePlatform } = useBrowserDevice();
  const redirectPath = isNativePlatform ? PUBLIC_PATHS.login : PUBLIC_PATHS.notFound;
  const isDifferentStudent = Boolean(studentId) && studentId !== currentStudentId;

  if (!isDifferentStudent && token) {
    navigate(PROTECTED_PATHS.home);
  }

  if (isDifferentStudent) {
    removeAccessToken();
    setCurrentStudentId(studentId);
  }

  useEffect(() => {
    if (!currentStudentId && !studentId && location.pathname !== '/login') {
      navigate(redirectPath);
    }
  }, [token, studentId, currentStudentId]);

  return {
    currentStudentId,
    studentId,
    token,
    isDifferentStudent,
  } as LoginProps;
};
