import { useState, useEffect } from 'react';
import { config } from '@/config';
import { Capacitor } from '@capacitor/core';

interface DeviceType {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  platform?: string; // ios, android, web
  isNativePlatform: boolean; // true if the app is running on a native is or android platform
}

export const useBrowserDevice = (): DeviceType => {
  const { breakpoints } = config.theme;

  const getDevice = (): DeviceType => {
    const windowWidth = Math.min(window.innerWidth, document.documentElement.clientWidth);
    return {
      isMobile: windowWidth < breakpoints.sm,
      isTablet: windowWidth >= breakpoints.sm && windowWidth < breakpoints.lg,
      isDesktop: windowWidth >= breakpoints.lg,
      platform: Capacitor.getPlatform(),
      isNativePlatform: Capacitor.isNativePlatform(),
    };
  };

  const [device, setDevice] = useState<DeviceType>(getDevice());

  useEffect(() => {
    const listener = () => setDevice(getDevice());
    window.addEventListener('resize', listener);

    return () => window.removeEventListener('resize', listener);
  }, []);

  return device;
};
