import { TFunction } from 'i18next';
import * as yup from 'yup';
import { LoginDto, MobileAppLoginDto } from '../adapters/dtos';

export class AuthValidation {
  static loginValidationSchema = (t: TFunction): yup.SchemaOf<LoginDto> =>
    yup.object().shape({
      password: yup.string().required(`${t('common:passwordRequiredMsg')}`),
      id: yup.string().required(`${t('common:studentIdRequiredMsg')}`),
    });

  static mobileAppLoginValidationSchema = (t: TFunction): yup.SchemaOf<MobileAppLoginDto> =>
    yup.object().shape({
      password: yup.string().required(`${t('common:passwordRequiredMsg')}`),
      studentId: yup.string().required(`${t('common:studentIdRequiredMsg')}`),
    });
}
