import { BlankLayout, ErrorPage, GlobalLoading, MainLayout, ProtectedRoutes } from '@/components';
import { StatusBar } from '@capacitor/status-bar';
import React, { Suspense, lazy, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import { useDocumentTitle } from './common';
import { AppProvider } from './common/context/AppContext';
import { TagContextInit } from './core/context/TagContext';
import { useGTM } from './core/gtag-manager/hooks/useGTM';
import './index.css';
import { AuthLogin } from './modules/auth';
import { ProfileSelectionScreen } from './modules/auth/screens/profile-selection';
import { PROTECTED_PATHS, PUBLIC_PATHS } from './routes';
import {
  AppTrackingTransparency,
  AppTrackingStatusResponse,
} from 'capacitor-plugin-app-tracking-transparency';
import PaymentRequestScreen from './modules/payment-request/screens';

const Home = lazy(() => import('./modules/home'));
const StudentProfile = lazy(() => import('./modules/student-profile'));
const StudyProduct = lazy(() => import('./modules/study-product'));
const StudentCourse = lazy(() => import('./modules/student-course'));
const LearningProgressList = lazy(() => import('./modules/learning-progress/screens/List'));
const LearningProgressDetail = lazy(() => import('./modules/learning-progress/screens/Detail'));

const App: React.FC = () => {
  useDocumentTitle();
  const { gtmHandler, initScript, noScript } = useGTM();

  const getTrackingStatus = async () => {
    const response = await AppTrackingTransparency.getStatus();
    return response;
  };

  const requestPermission = async () => {
    const response = AppTrackingTransparency.requestPermission();
    console.log('response', response);
  };

  useEffect(() => {
    StatusBar.setOverlaysWebView({ overlay: false });
    requestPermission();
  }, []);

  return (
    <AppProvider>
      <TagContextInit scripts={[initScript, noScript]} handlers={[gtmHandler]}>
        <Suspense fallback={<GlobalLoading />}>
          <Routes>
            <Route path={PUBLIC_PATHS.customerPaymentRequest} element={<PaymentRequestScreen />} />
            <Route element={<BlankLayout />}>
              <Route path={PUBLIC_PATHS.login} element={<AuthLogin />} />
              <Route path={PUBLIC_PATHS.profileSelection} element={<ProfileSelectionScreen />} />
            </Route>
            <Route element={<MainLayout />}>
              <Route element={<ProtectedRoutes />}>
                <Route path={PROTECTED_PATHS.profile} element={<StudentProfile />} />
                <Route path={PROTECTED_PATHS.studyProduct} element={<StudyProduct />} />
                <Route path={PROTECTED_PATHS.studentCourses} element={<StudentCourse />} />
                <Route path={PROTECTED_PATHS.learningProgress} element={<LearningProgressList />} />
                <Route
                  path={PROTECTED_PATHS.learningProgressDetail}
                  element={<LearningProgressDetail />}
                />
                <Route path={PROTECTED_PATHS.home} element={<Home />} />
              </Route>
            </Route>
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </Suspense>
      </TagContextInit>
    </AppProvider>
  );
};

export default App;
