import React from 'react';
import { Navigate } from 'react-router-dom';
import { AuthLoginDesktop } from './Desktop';
import { AuthLoginMobile } from './Mobile';
import { useBrowserDevice } from '@/common/hooks/useBrowserDevice';
import { PROTECTED_PATHS } from '@/routes';
import { useLogin } from '@/modules/auth/viewmodels';
import { MobileApp } from './MobileApp';

export const AuthLogin: React.FC<unknown> = () => {
  const { isMobile, isNativePlatform } = useBrowserDevice();
  const { isDifferentStudent, token } = useLogin();
  if (!isDifferentStudent && token && !isNativePlatform) {
    return <Navigate to={PROTECTED_PATHS.home} />;
  }

  const renderForm = () => {
    if (isNativePlatform) {
      return <MobileApp />;
    }
    return isMobile ? <AuthLoginMobile /> : <AuthLoginDesktop />;
  };

  return <div className="flex w-full items-center justify-center min-h-screen">{renderForm()}</div>;
};
