import { useState } from 'react';
import { StudentRepoImpl } from '../adapters/repositories';
import { useBaseViewModel } from '@/common';
import { FineOneUseCase } from '@/domains/student/usecases';
import { getAccessToken } from '@/common/storages';
import { StudentEntity } from '@/domains/student/entities';

export const useStudentViewModel = () => {
  const findOne = new FineOneUseCase(new StudentRepoImpl());
  const token = getAccessToken();

  const [student, setStudent] = useState<StudentEntity | undefined>(undefined);
  const { loading, error, catchAction } = useBaseViewModel();

  const actionGetCurrentStudent = async () => {
    if (token) {
      await catchAction(async () => {
        const res = await findOne.run();
        setStudent(res);
      });
    }
  };

  return {
    error,
    loading,
    student,
    actionGetCurrentStudent,
  };
};
