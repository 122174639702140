import React, { useEffect } from 'react';
import { ProfileManagement } from '../../components/profile-management/ProfileManagement';
import { useProfileSelectionViewModel } from '../../viewmodels/profile-selection.view-model';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '@/common';
import { Typography } from '@mx/ui';
import { StatusBar } from '@capacitor/status-bar';

export const ProfileSelectionScreen: React.FC = () => {
  const { profiles, handleAddProfile, handleSelectProfile, handleDeleteProfile } =
    useProfileSelectionViewModel();
  const { t } = useTranslation();
  const { height } = useWindowSize();
  // const paddingTopClass = height < 700 ? 'pt-16' : 'pt-[134px]';

  useEffect(() => {
    StatusBar.setOverlaysWebView({ overlay: true });
  }, []);

  return (
    <div
      className={`
          flex
          flex-col
          items-center
          justify-between
          box-border
          w-full
          px-4
          pb-10
          gap-8
          h-screen
          bg-cover
          bg-no-repeat
          bg-[url('/src/static/image/auth/mobile-bg-login-cover.png')]
          pt-[96px]
        `}
    >
      <div
        className="
          flex
          flex-col
          items-center
          gap-8
          w-full
        "
      >
        <Typography
          content={t('Chọn hồ sơ')}
          weight="semibold"
          fontTypo=""
          className="text-2xl md:text-4xl lg:text-5xl uppercase text-white"
        />
        <ProfileManagement
          profiles={profiles}
          addProfile={handleAddProfile}
          selectProfile={handleSelectProfile}
          deleteProfile={handleDeleteProfile}
        />
      </div>
    </div>
  );
};
