import { Preferences } from '@capacitor/preferences';

export const set = async <T>(key: string, value: T) => {
  await Preferences.set({
    key,
    value: JSON.stringify(value),
  });
};

export const get = async <T>(key: string) => {
  const ret = await Preferences.get({ key });
  if (!ret || !ret.value) return null;
  const object = JSON.parse(ret.value) as T;
  return object;
};

export const remove = async (key: string) => {
  await Preferences.remove({ key });
};

export const clear = async () => {
  await Preferences.clear();
};
