import { useAppContext } from '@/common';
import { removeAuthenticatedToken, setAccessToken, setCurrentStudentId } from '@/common/storages';
import { PROTECTED_PATHS, PUBLIC_PATHS } from '@/routes';
import { set } from '@/utils/mobile-app-storage';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router-dom';
import { StudentProfile } from '../types';

export const useProfileSelectionViewModel = () => {
  const { profiles, setProfiles, setSelectedProfile } = useAppContext();
  const navigate = useNavigate();
  const handleAddProfile = async () => {
    await set('profileSelected', undefined);
    setSelectedProfile(undefined);
    removeAuthenticatedToken();
    navigate(PUBLIC_PATHS.login);
  };
  const handleSelectProfile = async (profile: StudentProfile) => {
    const accessToken = profile?.accessToken;
    const id = profile?.id;
    console.log('profile', profile);
    if (accessToken && id) {
      setAccessToken(accessToken);
      setCurrentStudentId(id);
      setSelectedProfile(profile);
      await set('profileSelected', profile?.studentId);
      window.location.href = PROTECTED_PATHS.home;
    } else {
      removeAuthenticatedToken();
    }
  };
  const handleDeleteProfile = async (profileId: string) => {
    console.log('profileId', profileId);
    const newProfiles = profiles;
    delete newProfiles[profileId];
    await set('studentProfiles', newProfiles);
    setProfiles({ ...newProfiles });
    if (isEmpty(newProfiles)) {
      navigate(PUBLIC_PATHS.login);
    }
    removeAuthenticatedToken();
    setSelectedProfile(undefined);
  };
  return {
    profiles,
    handleAddProfile,
    handleSelectProfile,
    handleDeleteProfile,
  };
};
