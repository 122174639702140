import { stringToColor } from '@/utils/color';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StudentProfile } from '../../types';
import { IoTrashOutline } from 'react-icons/io5';

interface ProfileManagementProps {
  profiles: { [key: string]: StudentProfile };
  addProfile(): void;
  selectProfile(profile: StudentProfile): void;
  deleteProfile(profileId: string): void;
}

export const ProfileManagement: React.FC<ProfileManagementProps> = ({
  profiles,
  addProfile,
  selectProfile,
  deleteProfile,
}) => {
  const { t } = useTranslation();

  const createStringAvatar = (name: string) => {
    return name
      .split(' ')
      .map((name) => name[0])
      .join('')
      .toUpperCase();
  };

  return (
    <div className="grid grid-cols-2 gap-4 w-full max-w-xs px-4">
      {Object.values(profiles).map((profile) => {
        const initials = profile.fullName ?? 'Hồ Sơ';
        return (
          <div
            key={profile.studentId}
            className="relative flex flex-col items-center bg-white rounded-lg shadow-md p-4 hover:shadow-lg"
          >
            <div
              onClick={() => {
                selectProfile(profile);
              }}
              role="button"
              tabIndex={0}
              className="flex flex-col items-center cursor-pointer w-full"
            >
              <div
                className="w-16 h-16 rounded-full flex items-center justify-center mb-2"
                style={{
                  backgroundColor: stringToColor(profile.studentId),
                }}
              >
                <span className="text-xl font-bold text-white">{createStringAvatar(initials)}</span>
              </div>
              <p className="text-sm font-medium text-gray-900">{profile.fullName}</p>
            </div>
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                deleteProfile(profile.studentId);
              }}
              className="absolute top-2 right-2 p-1 border-none text-gray-500 hover:text-red-500 rounded-full hover:bg-gray-100"
            >
              <IoTrashOutline size={16} />
            </button>
          </div>
        );
      })}
      <div
        onClick={addProfile}
        className="flex flex-col items-center justify-center bg-gray-100 border-2 border-dashed border-gray-300 rounded-lg shadow-md p-4 hover:bg-gray-200"
      >
        <div className="w-16 h-16 bg-gray-200 rounded-full flex items-center justify-center cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            className="w-5 h-5 text-gray-500"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.75v14.5M4.75 12h14.5" />
          </svg>
        </div>
        <p className="mt-2 text-sm font-medium text-gray-500">{t('Thêm hồ sơ')}</p>
      </div>
      {profiles && (
        <p className="text-sm font-medium text-white col-span-2 mt-4 mb-2 text-center">
          {t('Vui lòng chọn hồ sơ cần xem')}
        </p>
      )}
    </div>
  );
};
