import { useAppContext, useBaseViewModel, useBrowserDevice } from '@/common';
import { getCurrentStudentId, setAccessToken, setCurrentStudentId } from '@/common/storages';
import { useDataLayerAction } from '@/core/context/TagContext';
import { LoginUseCase } from '@/domains/auth/usecases';
import { PROTECTED_PATHS, PUBLIC_PATHS } from '@/routes';
import { set } from '@/utils/mobile-app-storage';
import { Preferences } from '@capacitor/preferences';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MobileAppLoginDto } from '../adapters/dtos';
import { AuthUserRepoImpl } from '../adapters/repositories';
import { StudentProfile } from '../types';
import { AuthValidation } from '../validations';

interface MobileAppLoginFormProps {
  login(dto: MobileAppLoginDto): Promise<void>;

  form: UseFormReturn<MobileAppLoginDto>;
  authenticated: boolean;
  isLoading: boolean;
  error: Error | undefined;
  isInvalidAccessTokenState: boolean;
  setIsInvalidAccessTokenState(isInvalidAccessTokenState: boolean): void;
  // otp: string[];
  // otpController: {
  //   otpInputs: any;
  //   handleOtpChange: (index: number, value: string) => void;
  //   handleKeyDown: (index: number, e: React.KeyboardEvent<HTMLInputElement>) => void;
  // }
}

export const useMobileAppLoginForm = (): MobileAppLoginFormProps => {
  const { t } = useTranslation();
  const schema = AuthValidation.mobileAppLoginValidationSchema(t);
  const { loading, error, setError, setLoading } = useBaseViewModel();
  const { platform } = useBrowserDevice();
  const { setAuthenticated, authenticated, profiles, setProfiles } = useAppContext();
  const dataLayerAction = useDataLayerAction();
  const [isInvalidAccessTokenState, setIsInvalidAccessTokenState] = useState(false);
  const loginUseCase = new LoginUseCase(new AuthUserRepoImpl());
  const getStudentId = () => {
    const studentObjectId = getCurrentStudentId();
    if (profiles) {
      const profile = Object.values(profiles).find((profile) => profile.id === studentObjectId);
      return profile?.studentId;
    }
    return '';
  };
  const defaultValue: MobileAppLoginDto = {
    studentId: '',
    password: '',
  };
  const navigate = useNavigate();
  // TODO Remove this when deploy
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.Preferences = Preferences;
  const form = useForm({
    mode: 'onTouched',
    defaultValues: defaultValue,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const studentId = getStudentId();
    if (studentId) {
      form.setValue('studentId', studentId || '');
      setIsInvalidAccessTokenState(true);
    }
  }, []);

  useEffect(() => {
    setAuthenticated(false);
    dataLayerAction({
      event: 'ACCESS_LOGIN_PAGE',
      data: {
        user_id: getCurrentStudentId() || '',
        platform,
      },
    });
  }, [platform]);

  const setMobileAppProfile = async (profile: StudentProfile) => {
    const newProfiles = profiles
      ? { ...profiles, [profile.studentId]: profile }
      : { [profile.studentId]: profile };
    await set('studentProfiles', newProfiles);
    setProfiles(newProfiles);
  };

  const login = async (dto: MobileAppLoginDto) => {
    setLoading(true);

    try {
      const { accessToken, fullName, studentId } = await loginUseCase.run({
        id: dto.studentId,
        password: dto.password,
      });
      const profile: StudentProfile = {
        id: studentId,
        studentId: dto.studentId,
        fullName,
        lastLogin: new Date(),
        accessToken,
      };
      setMobileAppProfile(profile);
      setError(undefined);
      // Kiểm tra xem có phải đang ở trạng thái gia hạn access token cho 1 profile
      if (isInvalidAccessTokenState) {
        // Đúng
        setAuthenticated(true); // Set trang thái đăng nhập
        setIsInvalidAccessTokenState(false);
        setAccessToken(accessToken);
        setCurrentStudentId(studentId); // Update studentId in localStorage
        navigate(PROTECTED_PATHS.home); // redirect về home page
      } else {
        navigate(PUBLIC_PATHS.profileSelection);
      }
    } catch (e) {
      setError(e as Error);
    } finally {
      setLoading(false);
    }
  };

  return {
    form,
    setIsInvalidAccessTokenState,
    isInvalidAccessTokenState,
    login,
    isLoading: loading,
    authenticated,
    error,
  };
};
