import { navigateToLogin } from '@/common';
import { getAccessToken, removeAccessToken } from '@/common/storages';
import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { apiUrl } from './config';

const httpLink = createHttpLink({
  uri: apiUrl,
});

const authLink = setContext((operation, { headers }) => {
  const token = getAccessToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'X-Requested-With': 'vn.edu.mindx.compass',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  console.log('graphQLErrors:::: ', graphQLErrors);
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions }) => {
      if (extensions.code === 'UNAUTHENTICATED') {
        // ignore 401 error for a refresh request
        console.log('handle UNAUTHENTICATED');
        removeAccessToken();
        navigateToLogin();
      }
    });
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);
});
export const httpClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([errorLink, authLink, httpLink]),
});
